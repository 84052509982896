// Imports
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Constant } from "../../constant";
import { DomSanitizer } from '@angular/platform-browser';

// Component Decorator
@Component({
  selector: 'notFound',
  templateUrl: './notFound.html',
  styleUrls: ['./notFound.scss']
})

// Component Class
export class NotFoundComponent implements OnInit {
  public Constant = Constant;
  resource: any = {};
  constructor(
      public sanitizer:DomSanitizer,
  ) {

  }
  ngOnInit() {
    this.resource = {};
    this.initData();
  }
  initData() {
    
  }
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}