<div>
  <div class="navbar__group">
    <div class="container">
      <nav class="navbar navbar-toggleable-md navbar-inverse">
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar"
          aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span> </span>
          <span> </span>
          <span> </span>
        </button>
        <a class="navbar-brand">
          <img src="./assets/images/home/logo.svg" alt="">
        </a>

        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_customer_list_page">
                Khách hàng
                <span></span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_fee_dashboard_page">
                Quản lý phí
                <span></span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_agency_list_page">
                <span>Cộng tác viên</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_organization_list_page">
                <span>Tổ chức</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_financial_normal_plan_dashboard">
                <span>Sức khỏe tài chính</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active"
                [routerLink]="Constant.url_financial_comprehensive_plan_dashboard">
                <span>Hoạch định tài chính</span>
              </a>
            </li>
          </ul>
        </div>

        <ul id="nav-authen">
          <li>
            <a class="nav-link btn-border" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <strong>{{ userProfile?.shortName }}</strong>
              <svg class="nav-authen__icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_461_1050)" />
                <path
                  d="M9.14282 22.2965C9.14282 22.2965 9.14282 21.3616 9.14282 21.2374C9.14282 19.8667 11.1977 18.6201 13.4424 18.1819C13.4424 18.1819 14.129 17.8094 13.9415 17.1228C13.255 16.2512 13.0675 15.3163 13.0675 15.3163C12.9433 15.253 12.7559 15.1288 12.6317 15.0047C12.4442 14.7539 12.1959 14.0064 12.2592 13.4465C12.3201 12.9474 12.4442 13.1348 12.5075 13.0107C12.0084 11.8883 12.2592 10.4543 12.8192 9.3952C14.0024 7.33791 16.4955 7.96118 16.8704 8.70862C19.2394 8.27282 19.1761 12.0125 18.8036 12.9474C18.8036 12.9474 18.9886 13.0107 18.9886 13.8823C18.9277 14.8172 18.1803 15.3772 18.1803 15.3772C18.1803 15.6279 17.8687 16.3754 17.3696 17.062C16.9337 17.9336 17.8054 18.121 17.8054 18.121C20.0501 18.5568 22.105 19.8034 22.105 21.1741C22.105 21.2983 22.105 22.2332 22.105 22.2332C22.105 23.1072 18.7403 24.0421 15.6239 24.0421C12.5708 24.0421 9.14282 23.7305 9.14282 22.2965Z"
                  fill="white" />
                <defs>
                  <linearGradient id="paint0_linear_461_1050" x1="32" y1="16" x2="-1.57518e-06" y2="16"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#89D5A3" />
                    <stop offset="1" stop-color="#5CB8C8" />
                  </linearGradient>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                <path d="M1.23552 0L0 1.23552L5 6.23552L10 1.23552L8.76448 0L5 3.76448L1.23552 0Z" fill="#313131" />
              </svg>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <!-- <a routerLinkActive="active" [routerLink]="Constant.url_private_info_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.75 3.63636C3.75 1.62806 5.42893 0 7.5 0C9.57107 0 11.25 1.62806 11.25 3.63636C11.25 5.64467 9.57107 7.27273 7.5 7.27273C5.42893 7.27273 3.75 5.64467 3.75 3.63636ZM7.5 1.45455C6.25736 1.45455 5.25 2.43138 5.25 3.63636C5.25 4.84135 6.25736 5.81818 7.5 5.81818C8.74264 5.81818 9.75 4.84135 9.75 3.63636C9.75 2.43138 8.74264 1.45455 7.5 1.45455Z"
                                        fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 15.2727C0 11.2561 3.35786 8 7.5 8C11.6421 8 15 11.2561 15 15.2727V16H0V15.2727ZM1.54642 14.5455H13.4536C13.0845 11.6754 10.5597 9.45455 7.5 9.45455C4.44029 9.45455 1.91549 11.6754 1.54642 14.5455Z"
                                        fill="#313131" />
                                </svg>
                                <span>Thông tin tài khoản</span>
                            </a>
                            <a routerLinkActive="active" [routerLink]="Constant.url_change_password_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M8.25 10.0455V13.1364H6.75V10.0455H8.25Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12 6.18182V4.63636C12 2.07577 9.98528 0 7.5 0C5.01472 0 3 2.07577 3 4.63636V6.18182H0V17H15V6.18182H12ZM7.5 1.54545C5.84315 1.54545 4.5 2.9293 4.5 4.63636V6.18182H10.5V4.63636C10.5 2.9293 9.15685 1.54545 7.5 1.54545ZM1.5 7.72727V15.4545H13.5V7.72727H1.5Z"
                                        fill="#313131" />
                                </svg>
                                <span>Đổi mật khẩu</span>
                            </a>
                            <a routerLinkActive="active" [routerLink]="Constant.url_investor_list_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M6.75 6.18182V13.1364H5.25V6.18182H6.75Z" fill="#313131" />
                                    <path d="M9.75 6.18182V13.1364H8.25V6.18182H9.75Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.95943 0H11.0406L11.7906 2.31818H15V3.86364H13.5V17H1.5V3.86364H0V2.31818H3.20943L3.95943 0ZM4.79057 2.31818H10.2094L9.95943 1.54545H5.04057L4.79057 2.31818ZM3 3.86364V15.4545H12V3.86364H3Z"
                                        fill="#313131" />
                                </svg>
                                <span>Yêu cầu xóa tài khoản</span>
                            </a> -->
              <a (click)="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M0 0H9.16028V1.7H1.66551V15.3H9.16028V17H0V0Z" fill="#313131" />
                  <path
                    d="M12.4913 3.89792L17 8.5L12.4913 13.1021L11.3136 11.9L13.8119 9.35H4.16377V7.65H13.8119L11.3136 5.1L12.4913 3.89792Z"
                    fill="#313131" />
                </svg>
                <span>Đăng xuất</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="page-container">
    <div class="main-content">
      <router-outlet></router-outlet>
      <router-outlet name="popup"></router-outlet>
    </div>
  </div>
</div>